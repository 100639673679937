// PROD MAN
// var config = {
//     "base_url": "https://ppdb.man1gresik.sch.id",
//     "host_api": "https://api.mandigital.man1gresik.sch.id",
//     "api": "https://api.mandigital.man1gresik.sch.id/api",
//     "landing_page": "https://mandigital.man1gresik.sch.id",
//     "app_internal": "https://internal.mandigital.man1gresik.sch.id",
//     "app_external": "https://external.mandigital.man1gresik.sch.id",
//     "assets_url": "https://assets.mandigital.man1gresik.sch.id",
//     "secret_key": "m4nD1g1t4l",
//     "phone_admin": "6285888333359",
//     "web_man": {
//         "name": "MAN 1 Gresik",
//         "url": "https://man1gresik.sch.id/",
//     }
// }

// TRAINING MAN
// var config = {
//     "base_url": "https://ppdb.training.man1gresik.sch.id",
//     "host_api": "https://api.training.mandigital.man1gresik.sch.id",
//     "api": "https://api.training.mandigital.man1gresik.sch.id/api",
//     "landing_page": "https://training.mandigital.man1gresik.sch.id",
//     "app_internal": "https://internal.training.mandigital.man1gresik.sch.id",
//     "app_external": "https://external.training.mandigital.man1gresik.sch.id",
//     "assets_url": "https://assets.training.mandigital.man1gresik.sch.id",
//     "secret_key": "m4nD1g1t4l",
//     "phone_admin": "6285888333359",
//     "web_man": {
//         "name": "MAN 1 Gresik",
//         "url": "https://man1gresik.sch.id/",
//     }
// }

// DEV MAN
var config = {
    "base_url": "https://ppdb.dev.man1gresik.sch.id",
    "host_api": "https://api.dev.mandigital.man1gresik.sch.id",
    "api": "https://api.dev.mandigital.man1gresik.sch.id/api",
    "landing_page": "https://dev.mandigital.man1gresik.sch.id",
    "app_internal": "https://internal.dev.mandigital.man1gresik.sch.id",
    "app_external": "https://external.dev.mandigital.man1gresik.sch.id",
    "assets_url": "https://assets.dev.mandigital.man1gresik.sch.id",
    "secret_key": "m4nD1g1t4l",
    "phone_admin": "6285888333359",
    "web_man": {
        "name": "MAN 1 Gresik",
        "url": "https://man1gresik.sch.id/",
    }
}

// LOCAL DEV
// var config = {
//     "base_url": "http://localhost:3000",
//     "host_api": "https://api.dev.mandigital.man1gresik.sch.id",
//     "api": "https://api.dev.mandigital.man1gresik.sch.id/api",
//     "landing_page": "https://dev.man1gresik.itindonesia.my.id",
//     "app_internal": "https://internal.dev.mandigital.man1gresik.sch.id",
//     "app_external": "https://external.dev.mandigital.man1gresik.sch.id",
//     "assets_url": "https://assets.dev.mandigital.man1gresik.sch.id",
//     "secret_key": "m4nD1g1t4l",
//     "phone_admin": "6285888333359",
//     "web_man": {
//         "name": "MAN 1 Gresik",
//         "url": "https://man1gresik.sch.id/",
//     }
// }

export default config
